import styled from 'styled-components'

import { spacing } from '@fortum/elemental-ui'

import { BLOCK_BOTTOM_MARGIN } from '../constants'

export const RichTextUl = styled.ul`
  padding-left: ${spacing.m};
  margin-bottom: ${BLOCK_BOTTOM_MARGIN};
  list-style: disc;

  li > & {
    margin-bottom: 0;
    list-style: circle;
  }
`
