'use client'

import type { Options } from '@contentful/rich-text-react-renderer'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import type { Document } from '@contentful/rich-text-types'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import deepmerge from 'deepmerge'
import { type ReactNode, memo } from 'react'
import styled from 'styled-components'

import type { ContentTextProps } from '@fortum/elemental-ui'
import {
  ContentText,
  TableContainer,
  TableElement,
  Tbody,
  Td,
  Th,
  Tr,
  spacing,
} from '@fortum/elemental-ui'

import type { AnyEntry, AssetEntry } from '@/shared/contentful/types'

import { useEntryRenderers } from './entryRenderers'
import { renderAssetHyperlink } from './parts/AssetHyperlink'
import { renderEmbeddedAsset } from './parts/EmbeddedAsset'
import { renderEntryHyperlink } from './parts/EntryHyperlink'
import { EntryRenderer } from './parts/EntryRenderer'
import { renderRichTextHeading } from './parts/RichTextHeading'
import { RichTextHyperlink } from './parts/RichTextHyperlink'
import { RichTextOl } from './parts/RichTextOl'
import { RichTextParagraph } from './parts/RichTextParagraph'
import { RichTextUl } from './parts/RichTextUl'

type MaybeEmpty<T> = T | null | object
type Assets = { block?: MaybeEmpty<AssetEntry>[]; hyperlink?: MaybeEmpty<AssetEntry>[] }

type Entries = {
  block?: MaybeEmpty<AnyEntry>[]
  inline?: MaybeEmpty<AnyEntry>[]
  hyperlink?: MaybeEmpty<AnyEntry>[]
}

type Links = {
  assets?: Assets
  entries?: Entries
}

export type RichTextDocument = {
  json?: unknown | null
  links?: Links | null
}

type RichTextProps = {
  /**
   * Document.
   */
  document: RichTextDocument | undefined | null
  /**
   * Custom options for the renderer.
   */
  customOptions?: Partial<Options>
  /**
   * Font size.
   */
  size?: ContentTextProps['size']
}

const isEntry = (entry: unknown): entry is AnyEntry => Boolean((entry as AnyEntry)?.__typename)

const isValidAssetEntry = (entry: unknown): entry is AssetEntry =>
  Boolean((entry as AssetEntry)?.__typename)

export const RichText = memo(({ document, customOptions, size }: RichTextProps): ReactNode => {
  const entryRenderers = useEntryRenderers()
  const blockEntries = document?.links?.entries?.block?.filter(isEntry)
  const inlineEntries = document?.links?.entries?.inline?.filter(isEntry)
  const blockAssets = document?.links?.assets?.block?.filter(isValidAssetEntry)
  const hyperlinkEntries = document?.links?.entries?.hyperlink?.filter(isEntry)
  const hyperlinkAssets = document?.links?.assets?.hyperlink?.filter(isValidAssetEntry)

  const defaultOptions = {
    // Using `preserveWhitespace: true` causes missing key prop errors, so we use this instead
    renderText: (text) => text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
    renderNode: {
      [BLOCKS.UL_LIST]: (_node, children) => <RichTextUl>{children}</RichTextUl>,
      [BLOCKS.OL_LIST]: (_node, children) => <RichTextOl>{children}</RichTextOl>,
      [BLOCKS.LIST_ITEM]: (_node, children) => <li>{children}</li>,
      [BLOCKS.PARAGRAPH]: (_node, children) => <RichTextParagraph>{children}</RichTextParagraph>,
      [BLOCKS.HEADING_1]: renderRichTextHeading(1),
      [BLOCKS.HEADING_2]: renderRichTextHeading(2),
      [BLOCKS.HEADING_3]: renderRichTextHeading(3),
      [BLOCKS.HEADING_4]: renderRichTextHeading(4),
      [BLOCKS.HEADING_5]: renderRichTextHeading(5),
      [BLOCKS.HEADING_6]: renderRichTextHeading(5), // Heading 6 not supported by Elemental
      [BLOCKS.TABLE]: (_, children) => (
        <TableContainer mb={spacing.xs}>
          <TableElement>
            <Tbody>{children}</Tbody>
          </TableElement>
        </TableContainer>
      ),
      [BLOCKS.TABLE_ROW]: (_, children) => <Tr>{children}</Tr>,
      [BLOCKS.TABLE_CELL]: (_, children) => <Td size="small">{children}</Td>,
      [BLOCKS.TABLE_HEADER_CELL]: (_, children) => (
        <Th size="small" columnKey="key">
          {children}
        </Th>
      ),
      [BLOCKS.EMBEDDED_ENTRY]: EntryRenderer(entryRenderers, 'block', blockEntries),
      [BLOCKS.EMBEDDED_ASSET]: (node) => renderEmbeddedAsset(node, blockAssets),
      [INLINES.HYPERLINK]: (node, children) => (
        <RichTextHyperlink href={node.data.uri} fontSize="inherit">
          {children}
        </RichTextHyperlink>
      ),
      //TODO: Fix Hyperlinks in same manner as renderEmbeddedAsset
      [INLINES.ASSET_HYPERLINK]: (node, children) =>
        renderAssetHyperlink(node, children, hyperlinkAssets),
      [INLINES.ENTRY_HYPERLINK]: (node, children) =>
        renderEntryHyperlink(node, children, hyperlinkEntries),
      [INLINES.EMBEDDED_ENTRY]: EntryRenderer(entryRenderers, 'inline', inlineEntries),
    },
  } satisfies Options

  const options = deepmerge(defaultOptions, customOptions ?? {})

  if (!document?.json) {
    return null
  }

  return (
    <Container tag="div" color="inherit" size={size}>
      {documentToReactComponents(document.json as Document, options)}
    </Container>
  )
})

RichText.displayName = 'RichText'

const Container = styled(ContentText)`
  & > *:last-child {
    margin-bottom: 0;
  }
`
