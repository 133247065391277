import { createContext, useContext } from 'react'

const Context = createContext<string | undefined>(undefined)

export const useLinkColor = () => useContext(Context)

/**
 * Provides the color for rich text links.
 */
export const LinkColorProvider = Context.Provider
