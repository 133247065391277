import styled from 'styled-components'

import { spacing } from '@fortum/elemental-ui'

import { BLOCK_BOTTOM_MARGIN } from '../constants'

export const RichTextOl = styled.ol`
  padding-left: ${spacing.m};
  margin-bottom: ${BLOCK_BOTTOM_MARGIN};
  list-style: decimal;

  li > & {
    margin-bottom: 0;
  }
`
