import type { Block, Inline } from '@contentful/rich-text-types'

import { ContentText } from '@fortum/elemental-ui'

import type { AssetEntry } from '@/shared/contentful/types'
import { resolveAssetUrl } from '@/shared/contentful/utils'

import { EmbeddedImage } from './EmbeddedImage'
import { StyledLink } from '../../StyledLink'

const getMimeGroup = (contentType: string) => contentType.split('/')[0]

type Props = {
  asset: AssetEntry
}

export const EmbeddedAsset = ({ asset }: Props) => {
  const { url: assetUrl, contentType, description, fileName } = asset

  if (!assetUrl) {
    return <ContentText paragraph>File not found</ContentText>
  }

  const url = resolveAssetUrl(assetUrl)
  const mimeGroup = contentType && getMimeGroup(contentType)

  if (mimeGroup === 'image') {
    return <EmbeddedImage image={{ image: asset, altText: description }} />
  }

  // Fallback to link for other assets
  return (
    <ContentText paragraph>
      <StyledLink href={url} target="_blank" fontSize="inherit">
        {fileName}
      </StyledLink>
    </ContentText>
  )
}

export const renderEmbeddedAsset = (node: Block | Inline, assets?: AssetEntry[]) => {
  const asset = assets?.find((asset) => asset.sys.id === node.data.target?.sys?.id)

  if (asset?.__typename === 'Asset') {
    return <EmbeddedAsset asset={asset} />
  }
}
