import type { Block, Inline } from '@contentful/rich-text-types'
import type { ReactNode } from 'react'

import type { AssetEntry } from '@/shared/contentful/types'
import { getAssetUrl } from '@/shared/contentful/utils'

import { RichTextHyperlink } from './RichTextHyperlink'

type Props = {
  asset: AssetEntry
  children: ReactNode
}

export const AssetHyperlink = ({ asset, children }: Props) => {
  const url = getAssetUrl(asset)

  if (!url) {
    return <b>Invalid asset hyperlink</b>
  }

  return (
    <RichTextHyperlink href={url} target="_blank" fontSize="inherit">
      {children}
    </RichTextHyperlink>
  )
}

export const renderAssetHyperlink = (
  node: Inline | Block,
  children: ReactNode,
  assets?: AssetEntry[],
) => {
  const nodeLink = node.data.target
  const asset = assets?.find((a) => a.sys.id === nodeLink?.sys?.id)
  if (nodeLink.sys.linkType === 'Asset' && asset) {
    return <AssetHyperlink asset={asset}>{children}</AssetHyperlink>
  }

  return <b>No found asset hyperlink: {nodeLink?.sys?.id}</b>
}
