import { createContext, useContext } from 'react'

const Context = createContext<string | undefined>(undefined)

export const useImageSizes = () => useContext(Context)

/**
 * Provides the sizes attribute value for embedded images.
 */
export const ImageSizesProvider = Context.Provider
