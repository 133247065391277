'use client'

import type { CommonBoxProps } from '@fortum/elemental-ui'
import { Box, ButtonPill, spacing } from '@fortum/elemental-ui'

import { RichText } from '@/shared/components/RichText'
import type { CtaEntry } from '@/shared/contentful/types'
import { getLinkEntryUrl } from '@/shared/contentful/utils'
import { useTheme } from '@/shared/hooks/useTheme'
import { Link } from '@/shared/navigation'

import { ContentfulImage2 } from '../ContentfulImage2'
import { useImageSizes } from '../RichText/ImageSizesProvider'
import { StyledHeading } from '../StyledHeading'

export type InlineCtaProps = CtaEntry & {
  boxProps?: CommonBoxProps
}

/**
 * Inline call to action component used in rich text content.
 */
export const InlineCta = ({ title, content, primaryLink, image, boxProps }: InlineCtaProps) => {
  const { colors } = useTheme()
  const sizes = useImageSizes()

  return (
    <Box
      backgroundColor={colors.backgroundSecondary}
      display="flex"
      justifyContent="space-between"
      borderRadius={spacing.xxxs}
      {...boxProps}
    >
      <Box ph={spacing.xxxs} minWidth="0" p={{ default: spacing.xxs, l: spacing.xs }}>
        <StyledHeading title={title || ''} level={5} mb={spacing.xxxs}>
          {title}
        </StyledHeading>
        <Box mb={spacing.xxs}>
          <RichText document={content} size="s" />
        </Box>
        {primaryLink && (
          <ButtonPill
            tag={Link}
            href={getLinkEntryUrl(primaryLink)}
            width={{ default: '100%', s: 'auto' }}
          >
            {primaryLink.label}
          </ButtonPill>
        )}
      </Box>

      {image && (
        <Box
          width="16rem"
          flexShrink={0}
          position="relative"
          display={{ default: 'none', s: 'block' }}
        >
          <ContentfulImage2
            fill
            image={image}
            boxProps={{ borderRadius: `0 ${spacing.xxxs} ${spacing.xxxs} 0` }}
            sizes={sizes}
            maxWidth="500"
          />
        </Box>
      )}
    </Box>
  )
}
