import type { NodeRenderer } from '@contentful/rich-text-react-renderer'
import styled from 'styled-components'

import type { HeadingLevel, HeadingProps } from '@fortum/elemental-ui'
import { spacing } from '@fortum/elemental-ui'

import { StyledHeading as Heading } from '../../StyledHeading'

export const RichTextHeading = ({ level = 1, ...props }: HeadingProps) => (
  <StyledHeading
    level={level}
    mt={spacing.xs}
    mb={level < 3 ? spacing.xxs : spacing.xxxs}
    {...props}
  />
)

const StyledHeading = styled(Heading)`
  &:first-child {
    margin-top: 0;
  }

  b {
    font-weight: inherit;
  }
`

export const renderRichTextHeading =
  (level: HeadingLevel, styledAs?: HeadingLevel, headingProps?: HeadingProps): NodeRenderer =>
  // eslint-disable-next-line react/display-name
  (_, children) => (
    <RichTextHeading level={level} styledAs={styledAs} {...headingProps}>
      {children}
    </RichTextHeading>
  )
