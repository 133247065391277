'use client'

import type React from 'react'

import type { CommonBoxProps } from '@fortum/elemental-ui'
import { Box, ContentText, fontSizes, fontWeights, spacing } from '@fortum/elemental-ui'

import type { QuoteEntry } from '@/shared/contentful/types'
import { useTheme } from '@/shared/hooks/useTheme'

import { StyledHeading } from '../StyledHeading'

export type QuoteProps = QuoteEntry & {
  boxProps?: CommonBoxProps
}

/**
 * Quote with author.
 */
export const Quote = ({ simpleContent, author, authorDescription, boxProps }: QuoteProps) => {
  const theme = useTheme()

  return (
    <Box display="flex" gap={{ default: spacing.xxs, s: spacing.xs }} {...boxProps}>
      <QuoteMark aria-hidden="true">“</QuoteMark>
      <Box tag="blockquote" flexGrow={1} display="flex" flexDirection="column" gap={spacing.xxs}>
        {simpleContent && (
          <StyledHeading tag="p" level={4}>
            {simpleContent}
          </StyledHeading>
        )}
        {author && (
          <Box tag="footer">
            <ContentText fontWeight={fontWeights.medium} fontSize={fontSizes.l}>
              {author}
            </ContentText>
            {authorDescription && (
              <ContentText color={theme.colors.textSecondary} fontSize={fontSizes.m}>
                {authorDescription}
              </ContentText>
            )}
          </Box>
        )}
      </Box>
      <QuoteMark aria-hidden="true">”</QuoteMark>
    </Box>
  )
}

const QuoteMark = ({ children }: { children: React.ReactNode }) => {
  const { colors, font } = useTheme()

  return (
    <Box
      width={{ default: spacing.m, s: spacing.l }}
      textColor={colors.textPositive}
      fontWeight={fontWeights.bold}
      lineHeight={0.7}
      fontFamily={font.family}
      fontSize={{ default: '5rem', s: '7rem' }}
      flexShrink={0}
      textAlign="center"
    >
      {children}
    </Box>
  )
}

/**
 * Total horizontal padding of the quote text on tablet (S) and larger viewports.
 * This consists of the quote mark and the gap between the quote mark and the text.
 */
export const QUOTE_PADDING_TABLET = spacing.xxl // 56px + 24px
