import { Box, ContentText, spacing } from '@fortum/elemental-ui'

import type { ImageEntry } from '@/shared/contentful/types'

import { ContentfulImage2 } from '../../ContentfulImage2'
import { BLOCK_BOTTOM_MARGIN } from '../constants'
import { useImageSizes } from '../ImageSizesProvider'

type Props = {
  image: Pick<ImageEntry, 'image' | 'altText' | 'caption'>
}

export const EmbeddedImage = ({ image }: Props) => {
  const sizes = useImageSizes()

  return (
    <Box display="flex" flexDirection="column" mb={BLOCK_BOTTOM_MARGIN} gap={spacing.xxxxs}>
      <ContentfulImage2 image={image} boxProps={{ borderRadius: spacing.xxxxs }} sizes={sizes} />
      {image.caption && <ContentText size="xs">{image.caption}</ContentText>}
    </Box>
  )
}
