import type { Block, Inline } from '@contentful/rich-text-types'
import type { ReactNode } from 'react'

import type { AnyEntry } from '@/shared/contentful/types'
import {
  isArticleEntry,
  isTypeEnterprisePageEntry,
  isTypePageEntry,
} from '@/shared/contentful/types/contentTypeGuards'
import { getPageRoutePath } from '@/shared/contentful/utils'

import { RichTextHyperlink } from './RichTextHyperlink'

type Props = {
  entry: AnyEntry
  children: ReactNode
}

export const EntryHyperlink = ({ entry, children }: Props) => {
  if (isTypePageEntry(entry) || isTypeEnterprisePageEntry(entry) || isArticleEntry(entry)) {
    const path = getPageRoutePath(entry)
    return (
      <RichTextHyperlink href={path} fontSize="inherit">
        {children}
      </RichTextHyperlink>
    )
  }

  return <b>Unsupported entry hyperlink: {entry?.sys?.id}</b>
}

export const renderEntryHyperlink = (
  node: Block | Inline,
  children: ReactNode,
  hyperlinks?: AnyEntry[],
) => {
  const nodeLink = node.data.target
  const entry = hyperlinks?.find((hyperlink) => hyperlink?.sys?.id === nodeLink?.sys?.id)

  if (nodeLink.sys.linkType === 'Entry' && entry) {
    return <EntryHyperlink entry={entry}>{children}</EntryHyperlink>
  }

  return <b>Hyperlink entry not found: {nodeLink?.sys?.id}</b>
}
