'use client'

import type { Block, Inline } from '@contentful/rich-text-types'
import type { Entry } from 'contentful'
import { createContext, useContext } from 'react'
import type { ReactNode } from 'react'

import { InlineCta } from '@/shared/components/InlineCta'
import { QUOTE_PADDING_TABLET, Quote } from '@/shared/components/Quote'
import { StyledLink } from '@/shared/components/StyledLink'
import type {
  ArticleLinkEntry,
  CtaEntry,
  ImageEntry,
  LinkEntry,
  QuoteEntry,
  VideoEntry,
} from '@/shared/contentful/types'
import { getLinkEntryUrl } from '@/shared/contentful/utils'

import { InlineArticle } from '../InlineArticle'
import { BLOCK_BOTTOM_MARGIN } from './constants'
import { EmbeddedImage } from './parts/EmbeddedImage'
import { EmbeddedVideo } from './parts/EmbeddedVideo'

type InlineRenderer<T> = (props: { node: Inline; children: ReactNode; fields: T }) => ReactNode

type BlockRenderer<T> = (props: { node: Block; children: ReactNode; fields: T }) => ReactNode

export type EntryRenderer<T> = Partial<{
  inline: InlineRenderer<T>
  block: BlockRenderer<T>
}>

export type EntryRenderers<
  S extends Entry = Entry,
  T extends EntryRenderer<S> = EntryRenderer<S>,
> = Record<string, T>

export const entryRenderers = {
  Image: {
    block: ({ fields }) => <EmbeddedImage image={fields} />,
  } satisfies EntryRenderer<ImageEntry>,
  Video: {
    block: ({ fields }) => <EmbeddedVideo video={fields} />,
  } satisfies EntryRenderer<VideoEntry>,
  Cta: {
    block: ({ fields }) => <InlineCta {...fields} boxProps={{ mb: BLOCK_BOTTOM_MARGIN }} />,
  } satisfies EntryRenderer<CtaEntry>,
  Link: {
    inline: ({ fields }) => <StyledLink href={getLinkEntryUrl(fields)}>{fields.label}</StyledLink>,
  } satisfies EntryRenderer<LinkEntry>,
  Quote: {
    block: ({ fields }) => (
      <Quote
        {...fields}
        boxProps={{ mb: BLOCK_BOTTOM_MARGIN, mh: { default: 0, l: `-${QUOTE_PADDING_TABLET}` } }}
      />
    ),
  } satisfies EntryRenderer<QuoteEntry>,
  ArticleLink: {
    block: ({ fields }) => <InlineArticle {...fields} boxProps={{ mb: BLOCK_BOTTOM_MARGIN }} />,
  } satisfies EntryRenderer<ArticleLinkEntry>,
} satisfies EntryRenderers<never>

const Context = createContext(entryRenderers)

export const useEntryRenderers = () => useContext(Context)

export const EntryRenderersProvider = Context.Provider
