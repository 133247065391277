import styled from 'styled-components'

import { spacing } from '@fortum/elemental-ui'

import { BLOCK_BOTTOM_MARGIN } from '../constants'

export const RichTextParagraph = styled.p`
  margin-bottom: ${BLOCK_BOTTOM_MARGIN};

  li > & {
    margin-bottom: ${spacing.xxxs};
  }

  td > &,
  th > & {
    margin-bottom: 0;
  }
`
