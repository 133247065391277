import type { CommonBoxProps } from '@fortum/elemental-ui'
import { Box, ContentText, Placeholder } from '@fortum/elemental-ui'

import type { VideoEntry } from '@/shared/contentful/types'
import { getAssetUrl } from '@/shared/contentful/utils'

export type VideoProps = {
  /**
   * Contentful video
   */
  video: VideoEntry
  /**
   * Show controls
   */
  controls?: boolean
  /**
   * Poster image
   */
  poster?: string
  /**
   * Autoplay the video
   */
  autoPlay?: boolean
  /**
   * Mute the video
   */
  muted?: boolean
  /**
   * Loop the video
   */
  loop?: boolean
  /**
   * Allow fullscreen
   */
  allowFullScreen?: boolean
  /**
   * Box props
   */
  boxProps?: CommonBoxProps
}

const videoServices = [
  {
    hostnames: ['youtube.com', 'www.youtube.com'],
    getId: (url: URL) => url.searchParams.get('v'),
    embedUrl: (id: string) => `https://www.youtube-nocookie.com/embed/${id}?rel=0`,
  },
  {
    hostnames: ['youtu.be', 'www.youtu.be'],
    getId: (url: URL) => url.pathname.split('/')[1],
    embedUrl: (id: string) => `https://www.youtube-nocookie.com/embed/${id}?rel=0`,
  },
  {
    hostnames: ['vimeo.com', 'www.vimeo.com'],
    getId: (url: URL) => url.pathname.split('/')[1],
    embedUrl: (id: string) => `https://player.vimeo.com/video/${id}`,
  },
]

const transformToEmbedUrl = (videoUrl: string): string => {
  try {
    const urlObj = new URL(videoUrl)
    const service = videoServices.find((s) => s.hostnames.includes(urlObj.hostname))

    if (service) {
      const videoId = service.getId(urlObj)
      if (videoId) {
        return service.embedUrl(videoId)
      }
    }
  } catch {
    throw new Error('Invalid video URL provided: ' + videoUrl)
  }

  return videoUrl
}

const VideoEmbed = ({
  src,
  allowFullScreen,
  boxProps,
}: {
  src: string
  allowFullScreen: boolean
  boxProps?: CommonBoxProps
}) => (
  <Box
    tag="iframe"
    height="100%"
    width="100%"
    src={src}
    title="Embedded Video Player"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen={allowFullScreen}
    display="block"
    border="none"
    aspectRatio="16/9"
    {...boxProps}
  />
)

const VideoPlayer = ({
  src,
  controls,
  poster,
  autoPlay,
  muted,
  loop,
  boxProps,
}: {
  src: string
  controls: boolean
  poster?: string
  autoPlay: boolean
  muted: boolean
  loop: boolean
  boxProps?: CommonBoxProps
}) => (
  <Box
    tag="video"
    height="100%"
    width="100%"
    controls={controls}
    poster={poster}
    autoPlay={autoPlay}
    muted={muted}
    loop={loop}
    display="block"
    border="none"
    aspectRatio="16/9"
    {...boxProps}
  >
    <source src={src} type="video/mp4" />
  </Box>
)

export const Video = ({
  video,
  controls = true,
  poster,
  autoPlay = false,
  muted = false,
  loop = false,
  allowFullScreen = true,
  boxProps,
}: VideoProps) => {
  if (video.videoUrl?.includes('<iframe')) {
    const srcInsideIframe = video.videoUrl.split('src="')[1]?.split('"')[0]
    if (srcInsideIframe) {
      const embedSrc = transformToEmbedUrl(srcInsideIframe)
      return <VideoEmbed src={embedSrc} allowFullScreen={allowFullScreen} boxProps={boxProps} />
    }
  }

  if (video.videoUrl) {
    const embedSrc = transformToEmbedUrl(video.videoUrl)
    return <VideoEmbed src={embedSrc} allowFullScreen={allowFullScreen} boxProps={boxProps} />
  }

  if (video.video) {
    const videoSrc = getAssetUrl(video.video)

    if (videoSrc?.split('.').pop() === 'mp4') {
      return (
        <VideoPlayer
          src={videoSrc}
          controls={controls}
          poster={poster}
          autoPlay={autoPlay}
          muted={muted}
          loop={loop}
          boxProps={boxProps}
        />
      )
    }

    return <ContentText>Missing or invalid video</ContentText>
  }

  return <Placeholder />
}
