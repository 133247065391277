import type { NodeRenderer } from '@contentful/rich-text-react-renderer'
import type { Block, Inline } from '@contentful/rich-text-types'
import type { Entry } from 'contentful'

import type { EntryRenderers } from '@/shared/components/RichText/entryRenderers'
import type { AnyEntry } from '@/shared/contentful/types'

type Mode = 'inline' | 'block'

// because prettier tends to break lines I had to come up with such workaround
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Any = any

export const EntryRenderer =
  (entryRenderers: EntryRenderers<Any>, mode: Mode, linkedEntries?: AnyEntry[]): NodeRenderer =>
  // eslint-disable-next-line react/display-name
  (node, children) => {
    const inline = node as Inline
    const block = node as Block
    const castedNode = mode === 'inline' ? inline : block
    const target = castedNode.data.target as Entry
    const id = target.sys.id
    const fields = linkedEntries?.find((el) => el.sys.id === target.sys.id)
    const contentType = fields?.__typename
    const fallback = (
      <div>
        Fallback for {contentType} id: {id}
      </div>
    )
    if (!contentType) {
      return fallback
    }
    switch (mode) {
      case 'inline': {
        const component = entryRenderers[contentType]?.[mode]
        if (!component) {
          return fallback
        }
        return component({ node: inline, children, fields })
      }
      case 'block': {
        const component = entryRenderers[contentType]?.[mode]
        if (!component) {
          return fallback
        }
        return component({ node: block, children, fields })
      }
    }
  }
