'use client'

import type { ImageProps } from 'next/image'
import Image from 'next/image'
import { useState } from 'react'
import styled from 'styled-components'

import { type BoxPropValue, type CommonBoxProps } from '@fortum/elemental-ui'

import imageFallback from '@/images/media-not-found.svg'
import type { ImageEntry } from '@/shared/contentful/types'
import { getAssetUrl } from '@/shared/contentful/utils'
import { boxPropsToStyles } from '@/shared/style/boxStyles'

type OmitSrcAlt = Omit<ImageProps, 'src' | 'alt'>

export type ContentfulImage2Props = OmitSrcAlt & {
  /**
   * Contentful image entry.
   */
  image: Pick<ImageEntry, 'image' | 'altText'> | undefined
  /**
   * Elemental box props.
   */
  boxProps?: CommonBoxProps
  /**
   * Maximum width of the fetched image
   */
  maxWidth?: string
}

/**
 * Renders a Contentful image entry using the Next.js image component.
 * Shows a fallback image if the image file is not found.
 *
 */
export const ContentfulImage2 = ({
  image,
  boxProps,
  fill,
  sizes = '(min-width: 1025px) 1280px, 100vw',
  maxWidth = '1280',
  ...otherProps
}: ContentfulImage2Props) => {
  const [hasError, setError] = useState(false)
  const src = getAssetUrl(image?.image)
  const alt = image?.altText ?? ''
  const imageDetails = image?.image
  const width = imageDetails?.width ?? undefined
  const height = imageDetails?.height ?? undefined

  if (!src || !imageDetails || hasError) {
    return (
      <StyledImage
        src={imageFallback}
        alt="Image not found"
        fill={fill}
        $boxProps={boxProps}
        {...otherProps}
      />
    )
  }

  return (
    <StyledImage
      src={`${src}?w=${maxWidth}`}
      alt={alt}
      fill={fill}
      width={!fill ? width : undefined}
      height={!fill ? height : undefined}
      sizes={sizes}
      onError={() => setError(true)}
      $boxProps={boxProps}
      {...otherProps}
    />
  )
}

const StyledImage = styled(Image)<{ $boxProps?: CommonBoxProps; $aspectRatio?: BoxPropValue }>`
  width: 100%;
  object-fit: cover;

  ${({ $boxProps }) => $boxProps && boxPropsToStyles($boxProps)}
`
