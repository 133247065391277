import { spacing } from '@fortum/elemental-ui'

import type { VideoProps } from '../../Video'
import { Video } from '../../Video'
import { BLOCK_BOTTOM_MARGIN } from '../constants'

export type EmbeddedVideoProps = VideoProps

export const EmbeddedVideo = (props: VideoProps) => (
  <Video {...props} boxProps={{ borderRadius: spacing.xxxxs, marginBottom: BLOCK_BOTTOM_MARGIN }} />
)
