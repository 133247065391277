'use client'

import type { CommonBoxProps } from '@fortum/elemental-ui'
import { Box, ContentText, spacing } from '@fortum/elemental-ui'

import type { ArticleLinkEntry } from '@/shared/contentful/types'
import { getPageRoutePath } from '@/shared/contentful/utils'
import { useTheme } from '@/shared/hooks/useTheme'
import { getHoverColor } from '@/shared/utils/colors'

import { ContentfulImage2 } from '../ContentfulImage2'
import { useImageSizes } from '../RichText/ImageSizesProvider'
import { StyledLink } from '../StyledLink'

export type InlineArticleProps = ArticleLinkEntry & {
  boxProps?: CommonBoxProps
}

/**
 * Inline article link component used in rich text content.
 */
export const InlineArticle = ({ article, label, secondaryLabel, boxProps }: InlineArticleProps) => {
  const theme = useTheme()
  const sizes = useImageSizes()
  const { colors } = theme

  if (!article) {
    return
  }

  const url = getPageRoutePath(article)
  const articleImage = article.heroImage
  const primaryLabel = label ?? article.title

  return (
    <StyledLink
      href={url}
      noUnderlineHover
      borderRadius={spacing.xxxs}
      backgroundColor={colors.backgroundSecondary}
      transition="background-color 0.2s"
      hover={{ backgroundColor: getHoverColor(theme, colors.backgroundSecondary) }}
      display="flex"
      {...boxProps}
    >
      {articleImage && (
        <Box width="30%" maxWidth="12rem" flexShrink={0} position="relative" minHeight="6rem">
          <ContentfulImage2
            fill
            image={articleImage}
            boxProps={{ borderRadius: `${spacing.xxxs} 0 0 ${spacing.xxxs}` }}
            sizes={sizes}
            maxWidth="500"
          />
        </Box>
      )}
      <Box p={spacing.xxs} display="flex" flexDirection="column">
        {secondaryLabel && (
          <ContentText size="s" color={colors.textPositive}>
            {secondaryLabel}
          </ContentText>
        )}
        {primaryLabel && <ContentText size="l">{primaryLabel}</ContentText>}
      </Box>
    </StyledLink>
  )
}
